import React from 'react'
import Page from '../../components/Page'
import GameSetupForm from '../../components/GameSetupForm'

import s from './game-setup.module.scss' 

export default function GameSetup(props) {

  return (
    <div className={s.gameSetup}>
      <div>
        <Page title={"Game Setup"} content={GameSetupForm} />
      </div>
    </div>
  )
}